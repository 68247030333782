import { useState, useEffect, useRef } from "react"
import { PanZoom } from "react-easy-panzoom"
import axios from "axios"
import Popup from "./components/Popup"
import { getCircle, getParam } from "./utils"
import sitemapImg from "./img/sitemap.png"
import compassImg from "./img/compass.png"
import "./App.scss"

// const siteUrl = "http://anacapa.test/wp-json/wp/v2" // local
// const siteUrl = "https://anacapa.gjstage.com/wp-json/wp/v2" // staging
const siteUrl = "https://anacapanewhomes.com/wp-json/wp/v2"

const legend = [
  { color: "#13df13", label: "Available" },
  { color: "yellow", label: "Reserved" },
  { color: "red", label: "Sold" },
  { color: "purple", label: "Quick Move-In" },
]

const PlusButton = ({ onClick }) => (
  <button onClick={onClick}>
    <svg viewBox="0 0 24 24" height="48" width="48" role="img" xmlns="http://www.w3.org/2000/svg">
      <title>zoom in icon</title>
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0111 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 01-1.969 5.617zm-2.006-.742A6.977 6.977 0 0018 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 004.875-1.975l.15-.15zM10 10V7h2v3h3v2h-3v3h-2v-3H7v-2h3z" />
    </svg>
  </button>
)

const MinusButton = ({ onClick }) => (
  <button onClick={onClick}>
    <svg viewBox="0 0 24 24" height="48" width="48" role="img" xmlns="http://www.w3.org/2000/svg">
      <title>zoom out icon</title>
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0111 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 01-1.969 5.617zm-2.006-.742A6.977 6.977 0 0018 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 004.875-1.975l.15-.15zM7 10h8v2H7v-2z" />
    </svg>
  </button>
)

function App() {
  const appRef = useRef()
  const mapRef = useRef()
  const [maxZoom, setMaxZoom] = useState(1.6)
  const [zoomed, setZoomed] = useState(false)
  const [hoods, setHoods] = useState([])
  const [lots, setLots] = useState([])
  const [selectedLot, setSelectedLot] = useState(null)
  const [selectedHood, setSelectedHood] = useState(null)
  const [selectedRes, setSelectedRes] = useState(null)
  const [selectedElevation, setSelectedElevation] = useState(null)
  const [popupOpen, setPopupOpen] = useState(false)
  const [linkUrl, setLinkUrl] = useState(getParam("link"))

  useEffect(() => {
    const getHoodData = async () => {
      const res = await axios({
        method: "GET",
        url: siteUrl + "/neighborhoods?acf_format=standard",
      })

      if (res.status === 200) {
        setHoods(res.data)
      }
    }

    const getLotData = async () => {
      try {
        const res = await axios({
          method: "GET",
          url: siteUrl + "/lots?acf_format=standard&per_page=200",
        })

        if (res.status === 200) {
          setLots(res.data)
        }
      } catch (err) {
        console.log(err)
      }
    }

    getHoodData()
    getLotData()
  }, [])

  useEffect(() => {
    if (selectedLot) {
      let tempHood = hoods.find(hood => hood.slug === selectedLot.acf.neighborhood)
      let tempRes = tempHood.acf.residences.find(res => res.name === selectedLot.acf.plan)
      let tempElevation = tempRes[`elevation_${selectedLot.acf.elevation.toLowerCase()}`]

      setSelectedHood(tempHood)
      setSelectedRes(tempRes)
      setSelectedElevation(tempElevation)
    } else {
      setSelectedHood(null)
      setSelectedRes(null)
      setSelectedElevation(null)
    }
  }, [selectedLot, hoods])

  useEffect(() => {
    if (appRef.current) {
      if (appRef.current.clientWidth < 768) {
        setMaxZoom(3)
      } else {
        setMaxZoom(1.6)
      }
    }
  }, [appRef?.current?.clientWidth])

  const handleLotClick = lot => {
    setSelectedLot(lot)
    if (["available", "quick-move-in"].includes(lot.acf.status)) {
      setPopupOpen(true)
    }
  }

  const handlePopupClose = () => {
    setPopupOpen(false)
    setSelectedLot(false)
  }

  const renderedLots = () => {
    return lots
      .filter(lot => !!lot.acf.polygon)
      .map((lot, i) => {
        const circle = getCircle(lot.acf.polygon)
        return (
          <g
            key={lot.title.rendered}
            className={`lot ${lot.acf.neighborhood}`}
            onClick={() => handleLotClick(lot)}
          >
            <polygon points={lot.acf.polygon} />
            <circle
              className={lot.acf.status}
              cx={lot.acf.circle?.cx || circle.x}
              cy={lot.acf.circle?.cy || circle.y}
              r={10}
            />
          </g>
        )
      })
  }

  const renderPopupContent = () => {
    return (
      <div className="details">
        {selectedHood && (
          <img className="logo" src={selectedHood.acf.logo.url} alt={selectedHood.acf.logo.alt} />
        )}
        {selectedElevation && (
          <img
            className="elevation"
            src={selectedElevation.url}
            alt={selectedElevation.alt}
            width={selectedElevation.width}
            height={selectedElevation.height}
          />
        )}
        {selectedLot && selectedHood && (
          <div className="title" style={{ color: selectedHood.acf.color }}>
            <div>Homesite {selectedLot.title.rendered}</div>
            <div>{selectedLot.acf.plan_label}</div>
          </div>
        )}
        {selectedRes && (
          <div className="specs">
            <div>{selectedRes.square_footage}</div>
            <div>{selectedRes.bedrooms}</div>
            <div>{selectedRes.bathrooms}</div>
            <div>{selectedRes.garage}</div>
          </div>
        )}
        {selectedHood && (
          <a
            href={
              linkUrl.includes("anacapanewhomes")
                ? `${linkUrl}/residences/${selectedHood.slug}?plan=${selectedRes.slug}`
                : `${linkUrl}/residences/${selectedHood.slug}/${selectedRes.slug}`
            }
            target="_parent"
            style={{ color: selectedHood.acf.color }}
          >
            View Floor Plan{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 23.63 18.58"
              style={{ fill: selectedHood.acf.color }}
            >
              <polygon points="14.34 0 13.05 1.3 20.11 8.37 0 8.37 0 10.21 20.11 10.21 13.04 17.28 14.34 18.58 14.35 18.58 23.63 9.29 14.34 0" />
            </svg>
          </a>
        )}
      </div>
    )
  }

  const handleZoomClick = zoomIn => {
    if (zoomIn) {
      if (appRef.current.clientWidth < 768) {
        mapRef.current.zoomAbs(350, 200, maxZoom)
      } else {
        mapRef.current.zoomAbs(750, 200, maxZoom)
      }
    } else {
      mapRef.current.reset()
    }
    setZoomed(!zoomed)
  }

  const handlePreventPan = e => {
    return !zoomed
  }

  return (
    <div className="App" ref={appRef}>
      <PanZoom
        ref={mapRef}
        disableScrollZoom={true}
        disableDoubleClickZoom={true}
        minZoom={1}
        maxZoom={maxZoom}
        preventPan={handlePreventPan}
      >
        <div id="site-map-outer">
          <div id="site-map-inner">
            <img
              id="background"
              src={sitemapImg}
              alt="Anacapa Site Map"
              width={2342}
              height={2579}
            />
            <svg
              id="overlay"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 2342 2579"
              width={2342}
              height={2579}
            >
              {renderedLots()}
            </svg>
          </div>
        </div>
      </PanZoom>

      <div id="legend">
        <ul>
          {legend.map((item, i) => (
            <li key={item.label}>
              <svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
                <circle cx="25" cy="25" r="25" fill="#585858" />
                <circle cx="25" cy="25" r="23" fill={item.color} />
              </svg>
              <span>{item.label}</span>
            </li>
          ))}
          <li>
            <svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
              <circle cx="25" cy="25" r="25" fill="#585858" />
              <circle cx="25" cy="25" r="23" fill="#0e3490" />
              <path
                fill="#ffffff"
                d="M34.6,26.6c-0.2-3.9-0.5-8.5-0.4-12H34c-0.9,3.2-2.1,6.7-3.5,10.5l-4.9,13.5h-2.7l-4.5-13.2c-1.3-3.9-2.4-7.5-3.2-10.8
                h-0.1c-0.1,3.5-0.3,8.1-0.5,12.3l-0.7,11.9h-3.4L12.4,11H17l4.7,13.4c1.2,3.4,2.1,6.5,2.8,9.3h0.1c0.7-2.8,1.7-5.8,2.9-9.3L32.5,11
                H37l1.7,27.7h-3.5L34.6,26.6z"
              />
            </svg>
            <span>Model Home</span>
          </li>
        </ul>
      </div>
      <div id="zoom">
        {zoomed ? (
          <MinusButton onClick={() => handleZoomClick(false)} />
        ) : (
          <PlusButton onClick={() => handleZoomClick(true)} />
        )}
      </div>
      <div id="hoods">
        {hoods.length
          ? hoods
              .map((hood, i) => (
                <div className="hood" key={hood.slug} style={{ zIndex: zoomed ? "-1" : "1" }}>
                  <div className="logo">
                    <img src={hood?.acf?.logo?.url} alt={hood?.afc?.logo?.alt} />
                  </div>
                  <div className="type">{hood.acf.type.replace("&nbsp;", " ")}</div>
                  <div className="sqft">{hood.acf.square_footage}</div>
                  <div className="price">{hood.acf.pricing}</div>
                  <a
                    href={`${linkUrl}/residences/${hood?.slug}`}
                    target="_parent"
                    className="btn"
                    style={{ backgroundColor: hood.acf.color }}
                  >
                    View Neighborhood
                  </a>
                </div>
              ))
              .reverse()
          : null}
      </div>
      <div id="compass">
        <img src={compassImg} alt="compass" />
      </div>
      <Popup open={popupOpen} handleClose={handlePopupClose}>
        {renderPopupContent()}
      </Popup>
    </div>
  )
}

export default App
