import PopupComponent from "reactjs-popup"

export default function Popup({ open, handleClose, children }) {
  return (
    <PopupComponent open={open} closeOnDocumentClick onClose={handleClose}>
      <div className="modal">
        <button className="close" onClick={handleClose}>
          <svg viewBox="0 0 35 35">
            <rect
              x="-6.82"
              y="17.07"
              width="48.63"
              height="0.86"
              transform="translate(17.5 -7.25) rotate(45)"
            />
            <rect
              x="-6.82"
              y="17.07"
              width="48.63"
              height="0.86"
              transform="translate(42.25 17.5) rotate(135)"
            />
          </svg>
        </button>
        {children}
      </div>
    </PopupComponent>
  )
}
