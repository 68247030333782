export const getCircle = coordString => {
  // Get the center coordinates of the polygon
  var coords = coordString.split(" ")
  var posXmax, posYmax, posXmin, posYmin
  for (var a = 0, l = coords.length; a < l; a += 2) {
    if (a === 0) {
      posXmin = Number(coords[a])
      posXmax = Number(coords[a])
      posYmin = Number(coords[a + 1])
      posYmax = Number(coords[a + 1])
    } else {
      if (Number(coords[a]) < posXmin) {
        posXmin = Number(coords[a])
      }
      if (Number(coords[a + 1]) < posYmin) {
        posYmin = Number(coords[a + 1])
      }
      if (Number(coords[a]) > posXmax) {
        posXmax = Number(coords[a])
      }
      if (Number(coords[a + 1]) > posYmax) {
        posYmax = Number(coords[a + 1])
      }
    }
  }
  // Set position to center
  return { x: (posXmax + posXmin) / 2, y: (posYmax + posYmin) / 2 }
}

export const getParam = paramName => {
  var url = window.location.search.substring(1) //get rid of "?" in querystring
  var qArray = url.split("&") //get key-value pairs
  for (var i = 0; i < qArray.length; i++) {
    var pArr = qArray[i].split("=") //split key and value
    if (pArr[0] == paramName) return pArr[1] //return value
  }
}
